exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cources-js": () => import("./../../../src/pages/cources.js" /* webpackChunkName: "component---src-pages-cources-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-refundpolicy-js": () => import("./../../../src/pages/refundpolicy.js" /* webpackChunkName: "component---src-pages-refundpolicy-js" */),
  "component---src-pages-social-js": () => import("./../../../src/pages/social.js" /* webpackChunkName: "component---src-pages-social-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms&conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../../../src/templates/blog-post/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */),
  "component---src-templates-blog-posts-preview-blog-posts-preview-component-js": () => import("./../../../src/templates/blog-posts-preview/blog-posts-preview.component.js" /* webpackChunkName: "component---src-templates-blog-posts-preview-blog-posts-preview-component-js" */),
  "component---src-templates-tags-tags-js": () => import("./../../../src/templates/tags/tags.js" /* webpackChunkName: "component---src-templates-tags-tags-js" */)
}

